<template>
  <div class="Awarp">
    <dashCard class="photovoltaicBox9" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
      <template slot="title">{{commonLangHandler('cdzBox6_equipmentRate','逆变器设备利用率', getZEdata)}}</template>

      <div class="card-content1">
        <a-select v-model="searchObj['ITEM']" style="width: 240px" @change="handleChange" :getPopupContainer="triggerNode => triggerNode.parentNode">
          <a-select-option v-for="(item,key) in eqlist" :key="key" :value="item.assetNumber" >
            {{ item.assetName }}
          </a-select-option>
        </a-select>

      </div>
      <div class="card-content2" style="position: relative;
    top: 20px;">
        <chartBoard ref="chart1" :option="chartOption1"/>
      </div>
    </dashCard>
    <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
      <div style="height:calc(100vh)">
        <photovoltaicBox9   :option="option" :isModal="true" :orginSearchObj="searchObj"/>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'photovoltaicBox9',
  components: {
    dashCard: () => import('@/component/dashCard.vue'),
    chartBoard: () => import('@/component/chartBoard.vue'),
  },
  props: {
    gridOption: {
      type: Object,
      default: function() {
        return { w: 6, h: 6 }
      }
    },
    option: {
      type: Object,
      default: function() {
        return {
          visible: false
        }
      }
    },
    isModal: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    orginSearchObj: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  watch: {
    gridOption: {
      handler: function(newVal,oldVal) {
        this.$nextTick(_=>{
          this.getOperateWidth()
          var dom = this.$refs['chart1']
          if(!!dom) {
            dom.handleWindowResize()
          }
        })
      },
      deep: true,
      immediate: true
    },
    "option.visible": {//全屏化时执行
      handler: function(newVal,oldVal) {
        if(newVal) {
          if(this.isModal) {
            this.searchObj = this.orginSearchObj
            this.getData()
          }
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      eqlist:[],
      operateWidth: 64, //操作列宽度
      isLoading: false,//数据加载中

      timetType: [
        {
          code: '按天',
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        {
          code: '按周',
          name:'按周'
        },
        {
          code: '按月',
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: '按年',
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        }
      ],
      itemList: [
        {
          code: '光伏逆变器',
          name:'光伏逆变器'
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: '按天',//按天,按周,按月,按年
        VALUE: this.$moment(),//日期
        ITEM: '',//选中的项目
      },
      chartOption1: {},
      detailInfo: {
        chart1: {}
      },
      colorList: ["#1191E6","#7ED6C6","#1E6119","#160C9C"]
    }
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    }
  },
  methods: {
    getList(){
      this.$axios({
        url:  Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method:"post",
        data:{
          deptCode: this.currentSelectDeptInfo.CODE,
          type:"光伏逆变器"
        }
      }).then(res=>{
        if(!res.data.data){
          this.eqlist = []
        }else{
        this.eqlist = res.data.data
        if (this.eqlist.length > 0) {
          this.searchObj.ITEM=this.eqlist[0].assetNumber;
        }}
        this.getData()
      })
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE
      var dateStr = this.searchObj.VALUE.format('YYYY-MM-DD')
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf('month');
      var obj = {
        "按天": {
          type: "今日",
          startDate: VALUE.format('YYYY-MM-DD 00:00:00'),
          endDate: VALUE.format('YYYY-MM-DD 23:59:59'),
        },
        "按周": {
          type: "本周",
          startDate: VALUE.startOf('week').format('YYYY-MM-DD 00:00:00'),
          endDate: VALUE.endOf('week').format('YYYY-MM-DD 23:59:59'),
        },
        "按月": {
          type: "本月",
          startDate: monthVALUE.startOf('month').format('YYYY-MM-DD 00:00:00'),
          endDate: monthVALUE.endOf('month').format('YYYY-MM-DD 23:59:59'),
        },
        "按年": {
          type: "全年",
          startDate: VALUE.startOf('year').format('YYYY-MM-DD 00:00:00'),
          endDate: VALUE.endOf('year').format('YYYY-MM-DD 23:59:59'),
        }
      }
      return obj[TYPE]
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal
      if(!this.option.visible) {
        this.getData()
      }
    },
    // 右侧操作列宽度
    getOperateWidth () {
      if(!this.$el.querySelector) {
        return
      }
      var dom = this.$el.querySelector(".card-head-operate")
      if(!!dom) {
        var width = dom.clientWidth
        this.operateWidth = width > 0 ? width + 12 : width
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function(value) {
      if(!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },


    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type
      this.getData()
    },
    openChange(status) {
      this.panelOpen = status
    },
    // 年份弹窗关闭
    panelChange(value){
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData()
    },
    onChange(value) {
      //console.log("onChange",value,this.searchObj)
      this.getData()
      this.$forceUpdate()
    },
    // 切换项目
    handleChange(value) {
      //console.log(this.searchObj.ITEM)
      this.getData()
    },
    //每月报警预警数
    getData() {

      let data = {}
      data = {
        "assetNumber":this.searchObj.ITEM,
      }
      // Object.assign(data, this.transformSearch())
      // data.dateType = data.type
      // delete data.type
      //console.log(data)
      this.isLoading = true
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/inverter/usagerate",
        method:"post",
        data
      }).then(res=>{
        //console.log(res)
        let arr2 = []
        let arr = []

        this.detailInfo.chart1 = chart1
        //console.log(res.data.data)
        var chart1 = {

          percent:parseFloat(res.data.data.utilization)
        }
        this.detailInfo.chart1 = chart1
        this.initEchart2()
      }).catch((wrong)=>{ }).finally(()=>{
        this.isLoading = false
      })

    },
    initEchart2() {
      var { percent } = this.detailInfo.chart1;
      //console.log(percent)
      var option = {
        animation: true,
        layoutAnimation: false,
        color: ["#DBDBDB", "#51A34B"],
        tooltip: {
          trigger: "item",
        },
        grid: {

          top: "5%",
          containLabel: true,
        },
        angleAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          min: 0,
          max: 6.666,
          // boundaryGap: ['0', '10'],
          startAngle: 225,
        },
        radiusAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: ["a", "b", "c"],
          z: 10,
        },
        polar: [
          {
            center: ["50%", "50%"], //中心点位置
            radius: "97%", //图形大小
          },
        ],
        series: [
          {
            type: "bar",
            data: [, , 5],
            z: 0,
            silent: true,
            coordinateSystem: "polar",
            barMaxWidth: 1,
            name: "C",
            roundCap: true,
            barWidth: 10,
            color: {
              type: "linear",
              x: 1,
              y: 0,
              x2: 0,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "#2093E3", // 0% 处的颜色
                },
                {
                  offset: 0.5,
                  color: "#51A34B ", // 0% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
            barGap: "-100%",
          },
          // 背景
          {
            animation: true,
            name: this.commonLangHandler('cdzBox6_equipmentRate','设备利用率', this.getZEdata),
            type: "gauge",
            radius: "100%",
            center: ["50%", "50%"],
            splitNumber: 12,
            // 展示当前进度
            progress: {
              show: true,
              width: 5,
              itemStyle: {
                color: "#DBDBDB",
                fontFamily: "ABBvoice_WCNSG_Rg",
              },
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              show: false,
            },
            // 刻度标签
            axisLabel: {
              show: false,
            },
            // 表盘中指针的固定点
            anchor: {
              show: false,
            },
            detail: {
              show: false,
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            data: [
              {
                value: 100,
              },
            ],
          },
          // 内容
          {
            name: "",
            type: "gauge",
            radius: "100%",
            center: ["50%", "50%"],
            min: 0,
            max: 100,
            zlevel: 10,
            splitNumber: 12,
            // 展示当前进度
            progress: {
              show: true,
              width: 5,
              itemStyle: {
                color: "#51A34B",
                fontFamily: "ABBvoice_WCNSG_Rg",
              },
            },
            // 仪表盘指针
            pointer: {
              show: false,
            },
            // 仪表盘轴线相关配置
            axisLine: {
              show: false,
            },
            // 刻度样式
            axisTick: {
              show: false,
            },
            // 分隔线样式
            splitLine: {
              show: false,
            },
            splitNumber: 1,
            // 刻度标签
            axisLabel: {
              show: true,
              padding: [0, 10, 0, 10],
              distance: -30,
            },
            // 表盘中指针的固定点
            anchor: {
              show: false,
            },
            detail: {
              borderRadius: 8,
              offsetCenter: ["0%", "-15%"],
              fontSize: 30,
              fontWeight: "bolder",
              formatter: "{value}",
              color: "rgba(0,0,0,.65)",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            data: [
              {
                value: percent,
                name: this.commonLangHandler('photovoltaicBox9_equipmentRate','设备利用率(%)', this.getZEdata) ,
              },
            ],
          },
        ],
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    initEchart() {

      var { categoryData, seriesData } = this.detailInfo.chart1;

      var series = [];
      //console.log(seriesData)

      //console.log(series)
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "0%",
          right: "6%",
          bottom: "10%",
          top: "5%",
          containLabel: true,
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          // formatter: function (params) {
          //   let str = ""
          //   str += params[0].axisValueLabel + "<br>"
          //   str +="<div style='width: 100%;display: flex;justify-content: space-between;align-items: center;margin-top: 10px'>" + "排名" + "<span style='margin-right: 10px'></span>" + vm.detailInfo.chart1.top[params[0].dataIndex] +"</div>"
          //   params.forEach((item,index)=>{
          //     str +="<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" + "<span>" + item.marker + item.seriesName + "</span>" + "<span style='margin-right: 10px'></span>" + item.value +"</div>"
          //   })
          //   str += "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" + "剩余空间" + "<span style='margin-right: 10px'></span>" + vm.detailInfo.chart1.freeSpace[params[0].dataIndex] +"</div>"
          //   return str
          // }
        },
        xAxis: {
          type: "value",
          name: "单位(%)",
          nameLocation: "start", // 在头部
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,

            align: "center",
            verticalAlign: "top",
            padding: [28, 0, 0, 100],
            // padding: [上, 右, 下, 左],
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,

          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        yAxis: {
          type: "category",
          name: "",
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#696969",
            },
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,

          },
        },
        // 控住柱状图样式
        series: {
          type: "bar",
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          name: '利用率',
          data: seriesData,
          smooth: false,
          showSymbol: false,
          barGap: 0,
          barWidth: 10,
          emphasis: {
            focus: "series",
          },

        },
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return
      this[optionName] = option
      setTimeout(() => {
        this.$refs[refName].updateChartView()
      }, 500)
    },

  },
  mounted() {
    this.getOperateWidth()
    if(!this.option.visible) {
      this.getList()

    }
  }
}
</script>

<style lang="less" scoped>
.photovoltaicBox9 {
  .card-content1 {
    .content1-line {
      margin-top: 20px;
      display: flex;
      width: 70%;
      >div {
        // height: 68px;
        // width: 185px;
        display: flex;
        flex-direction: column;
        min-width: 118px;
        height: 84px;
        background: #fafafa;
        border-radius: 4px;
        margin-right: 16px;
        box-sizing: border-box;
        padding: 16px;
        >span {
          &:nth-child(1) {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0,0,0,0.65);
            line-height: 22px;
          }
          &:nth-child(2) {
            font-size: 32px;
            font-weight: 500;
            color: #1d2129;
            line-height: 40px;
            font {
              margin-left: 6px;
              font-size: 12px;
              font-weight: 400;
              color: rgba(0,0,0,0.45);
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    div{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
